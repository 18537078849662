import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { config } from '@/config';

import Dashboard from '@/components/Index/Dashboard';
import Wrapper from '@/components/Wrapper';
import Webpage from '@/components/Index/Webpage';
import WebpageASB from '@/components/Index/Webpage-ASB';
import WebpageAEG from '@/components/Index/ARIS/Webpage-AEG';
import ForbiddenWebpage from '@/components/Index/ForbiddenWebpage';

const Index = ({ webpageUsername }) => {
  const [renderItem, setRenderItem] = useState('');
  let { publicError } = useSelector(state => state.public);

  useEffect(() => {
    if (publicError) return setRenderItem(<ForbiddenWebpage />);
    let WEBPAGE;

    if (config().ASB_SYSTEM) {
      WEBPAGE = <WebpageASB webpageUsername={webpageUsername} />;
    } else if (config().AEGLEADS) {
      WEBPAGE = <WebpageAEG webpageUsername={webpageUsername} />;
    } else {
      WEBPAGE = <Webpage webpageUsername={webpageUsername} />;
    }

    setRenderItem(webpageUsername ? WEBPAGE : <Dashboard />);
  }, []);

  return renderItem || <Wrapper />;
};

export default Index;
