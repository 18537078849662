import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import moment from 'moment';
import Router from 'next/router';

import SVGIcon from '@/components/SVGIcon';
import { setSessionItem } from '@/utils/storage';

import styles from './DashboardCards.module.scss';
import Scroll from '@/components/Helpers/Scroll';
import Loader from '@/components/Helpers/Loader';

/**
 * The MultiInput Functional Component
 * @typedef Props:
 * @property {function} redirectToContactDetails - redirect to contact details
 * @property {function} loaderWithCondition -
 * @property {function} redirectBirthdays - function for redirect
 * @typedef TextFieldProps
 * @property {string} title - title text
 */

const DashboardBirthdaysCard = ({
  type,
  title,
  birthdays,
  loaderWithCondition,
  redirectBirthdays,
  redirectToContactDetails,
  loadMore,
  clientBirthdayPageLoaded,
  prospectBirthdayPageLoaded
}) => {
  const sendEmail = id => {
    setSessionItem('contactIds', JSON.stringify([id]));
    Router.push('/contacts/email?birthday');
  };

  return (
    <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
      <div className={styles.bigCardTitle}>
        <div
          className={classNames(
            'small-title',
            styles.dark,
            styles.description,
            styles.titled,
            styles.bdaysTitle,
            'DashboardsCardsResponsiveGridLayoutCancelSelector'
          )}
        >
          <div className={styles.birthdayIconWrapper}>
            <SVGIcon type="birthday" />
          </div>
          <p>
            <b>{title}</b>
            <br />
            <b
              className={classNames(birthdays?.length > 0 && styles.link)}
              onClick={() => {
                if (birthdays?.length > 0) {
                  redirectBirthdays();
                }
              }}
              style={{ fontSize: '16px' }}
            >
              {birthdays?.length && birthdays[0].total_count}
            </b>
          </p>{' '}
        </div>
      </div>
      <div className={styles.sectionContent}>
        {birthdays === undefined || !!birthdays.length ? (
          loaderWithCondition(
            birthdays,
            <div className={styles.birthdaysScrollBody}>
              <Scroll>
                {birthdays?.map(contact => (
                  <div key={contact.id} className={styles.bigCardSection}>
                    <div className={styles.info}>
                      <p
                        onClick={() => redirectToContactDetails(contact.number)}
                        className={classNames(
                          styles.title,
                          styles.linkTitle,
                          styles.smallDescription,
                          'DashboardsCardsResponsiveGridLayoutCancelSelector'
                        )}
                      >
                        {contact.first_name} {contact.last_name}
                      </p>
                      <p className={classNames(styles.age, styles.smallDescription)}>
                        {contact.dob && 'Turns ' + moment().diff(contact.dob, 'years', false)}
                      </p>
                    </div>

                    <p
                      onClick={() => sendEmail(contact.id)}
                      className={classNames(
                        'small-title',
                        styles.actionButton,
                        'DashboardsCardsResponsiveGridLayoutCancelSelector'
                      )}
                    >
                      Send Email
                    </p>
                  </div>
                ))}
                {(type === 'client' && !clientBirthdayPageLoaded) ||
                (type === 'prospect' && !prospectBirthdayPageLoaded) ? (
                  <Loader loading smallHeight />
                ) : (
                  birthdays?.length < (birthdays?.length && birthdays[0].total_count) && (
                    <span
                      onClick={loadMore}
                      className={classNames(
                        'd-flex justify-content-center',
                        'DashboardsCardsResponsiveGridLayoutCancelSelector',
                        styles.title,
                        styles.linkTitle
                      )}
                    >
                      Load more
                    </span>
                  )
                )}
              </Scroll>
            </div>
          )
        ) : (
          <div className={styles.bigCardSection}>
            <p className={classNames(styles.middleTitle, styles.noContent)}>No birthdays today</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(DashboardBirthdaysCard);
