import React from 'react';
import { withErrorBoundary } from '@/utils/errors';

import SkeletonElement from '@/components/Helpers/SkeletonLoaders/SkeletonElement';
import SkeletonWrapper from '@/components/Helpers/SkeletonLoaders/SkeletonWrapper';

import styles from './SmallCard.module.scss';

const SmallCard = ({ loading }) =>
  loading ? (
    <div className={styles.smallCard}>
      <SkeletonWrapper noMargin card>
        <SkeletonElement type="paragraph margin-bottom" />
        <div className={styles.cardElement}>
          <SkeletonElement type="icon" />
          <div className={styles.text}>
            <SkeletonElement type="paragraph margin-bottom margin-left" />
            <SkeletonElement type="paragraph margin-left" />
          </div>
        </div>
        <div className={styles.cardElement}>
          <SkeletonElement type="icon" />
          <div className={styles.text}>
            <SkeletonElement type="paragraph margin-bottom margin-left" />
            <SkeletonElement type="paragraph margin-left" />
          </div>
        </div>
      </SkeletonWrapper>
    </div>
  ) : null;

export default withErrorBoundary(SmallCard);
