import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { connect } from 'react-redux';
import { publish } from '@/utils/publish';
import classNames from 'classnames';
import Select from 'react-select';
import Router from 'next/router';

import styles from './Dashboard.module.scss';

import { setStateAsync } from '@/utils/helpers';
import { setItem, getItem } from '@/utils/storage';
import { abortSignal } from '@/utils/request';
import Wrapper from '@/components/Wrapper';
import DashboardCards from '@/components/Dashboard/DashboardCards';
import EventModal from '@/components/Calendar/EventModal';
import Modal from '@/components/Base/Modal';
import ActiveCards from '@/components/Dashboard/ActiveCards';

import { changeRedirect } from '@/utils/helpers';

import MiniCalendar from '@/components/Calendar/Calendar';
import { DASHBOARD_CONTACT_GROUPS } from '@/store/models/contact';
import { LAYOUT_C1, LAYOUT_ARIS, LAYOUT } from '@/store/models/dashboard';
import { fetchPools, loginAsUser, LOGIN_TYPES } from '@/store/actions/admin/users.actions';
import { fetchSources, fetchStatuses, clearContacts } from '@/store/actions/contacts.actions';
import { searchUsers } from '@/store/actions/search.actions';
import { fetchSetting } from '@/store/actions/public.actions';
import { fetchDashboardInfo, resetDashboardInfo } from '@/store/actions/currentUser.actions';
import { fetchCurrentAccount } from '@/store/actions/currentAccount.actions';
import { MANAGERS_ROLES } from '@/store/models/role';
import { config } from '@/config';

// loaders
import LongTitle from '@/components/Helpers/SkeletonLoaders/elements/LongTitle';
import Selector from '@/components/Helpers/SkeletonLoaders/elements/Selector';
import CardsWrapper from '@/components/Helpers/SkeletonLoaders/pages/Dashboard/CardsWrapper';
import AsyncSelect from 'react-select/async';
import { getLoginStack } from '@/utils/storage';

const selectedContacts = 'selectedContacts';
const selectImpersonated = 'selectImpersonated';
const DASHBOARD_INFO_TYPES = (() => {
  let data = ['sms_responders', 'soa'];
  if (config().DASHBOARD_AFFINITY_LEADS) data.push('affinity_leads');
  if (config().ASBAGENTMN) data.push('spouse_t65');

  if (config().BROKERAGEASB) return data.concat(['all', 'progress', 'client', 'closed', 'new']);
  if (config().MYASBFINANCIAL) {
    return data.concat([
      'active_clients',
      'hot_leads',
      'lapsed',
      'client_birthdays',
      'appointment',
      'followup',
      'task',
      'email_logs',
      'policy_aniversary',
      'recently_saved',
      'pending',
      'summary_prospects',
      'summary_leads',
      'summary_orphans',
      'summary_other',
      'client_aep_opps',
      'data_capture_aep_opps',
      'life_cross_sell',
      'annuity_cross_sell',
      'medicare_cross_sell',
      'clients_w_annuity',
      'clients_w_life'
    ]);
  }
  if (config().ASB_SYSTEM) {
    return data.concat([
      'client_aep_opps',
      'data_capture_aep_opps',
      'life_cross_sell',
      'annuity_cross_sell',
      'medicare_cross_sell',
      'clients_w_annuity',
      'clients_w_life',
      'all',
      'prospect',
      'lead',
      'deceased',
      'active_clients',
      'aftercare_clients',
      'hot_leads',
      'hot_prospects',
      'lapsed',
      'client_birthdays',
      'prospect_birthdays',
      'appointment',
      'followup',
      'task',
      'email_logs',
      'policy_aniversary',
      'recently_saved'
    ]);
  }
  if (config().CAREINADVANCE) {
    return data.concat([
      'all',
      'prospect',
      'lead',
      'deceased',
      'active_clients',
      'aftercare_clients',
      'hot_leads',
      'hot_prospects',
      'lapsed',
      'client_birthdays',
      'prospect_birthdays',
      'appointment',
      'followup',
      'task',
      'email_logs',
      'policy_aniversary',
      'recently_saved',
      'followup_pastdue',
      'followup_today'
    ]);
  }
  if (config().AEGLEADS) {
    return data.concat([
      'all',
      'prospect',
      'lead',
      'deceased',
      'client',
      'active_clients',
      'aftercare_clients',
      'hot_leads',
      'hot_prospects',
      'lapsed',
      'client_birthdays',
      'prospect_birthdays',
      'appointment',
      'followup',
      'task',
      'email_logs',
      'policy_aniversary',
      'older_policies',
      'recently_saved',
      'followup_pastdue',
      'followup_today',
      'pastdue',
      'client_aep_opps',
      'data_capture_aep_opps',
      'life_cross_sell',
      'annuity_cross_sell',
      'medicare_cross_sell',
      'part_d_clients'
    ]);
  }

  return data.concat([
    'all',
    'prospect',
    'lead',
    'deceased',
    'active_clients',
    'aftercare_clients',
    'hot_leads',
    'hot_prospects',
    'lapsed',
    'client_birthdays',
    'prospect_birthdays',
    'appointment',
    'followup',
    'task',
    'email_logs',
    'policy_aniversary',
    'recently_saved'
  ]);
})();

class Dashboard extends Component {
  state = {
    groupOptions: DASHBOARD_CONTACT_GROUPS(this.props.currentUser),
    contact_group: DASHBOARD_CONTACT_GROUPS(this.props.currentUser)[0].value,
    all_downline: 'false',
    cardsModalVisible: false,
    activeFields: {},
    tempActiveFields: {},
    resetActive: false,
    editEventModalIsVisible: false,
    editEvent: {},
    loading: true,
    users: '',
    userId: '',
    notesForUsers: '',
    miniNotesForUsers: '',
    selectedImpersonated: '',
    clientBirthdayPage: 1,
    prospectBirthdayPage: 1,
    clientBirthdayPageLoaded: true,
    prospectBirthdayPageLoaded: true
  };

  setStateAsync = setStateAsync.bind(this);
  isRM = this.props.accountType === 'regional_manager';

  get impersonatedUser() {
    const userArray = this.props.currentUser.impersonated_users;
    const user = userArray?.find(user => user.user_id === this.state.selectedImpersonated);
    if (!user) return null;
    return {
      value: user.user_id,
      label: `${user.first_name} ${user.last_name}`
    };
  }

  get loggedAsUser() {
    const login_type = getLoginStack().peek();
    return login_type === 'impersonating_as_rm' || login_type === 'impersonating' || login_type === 'logged_as_admin';
  }

  async componentDidMount() {
    this.props.clearContacts();
    await this.props.resetDashboardInfo();
    if (await this.changePasswordCheck()) return;
    await changeRedirect(this.isRM, '/contacts');

    if (this.accountBillingValidation()) {
      let value = this.accountBillingValidation();
      if (value) {
        await changeRedirect(value, '/billing/plan');
        return;
      }
    } else if (this.twoFactorValidation && !this.loggedAsUser) {
      let value = this.twoFactorValidation;
      if (value) {
        await changeRedirect(value, '/account/profile?tab=two_factor');
        return;
      }
    }

    await this.validateLayout();

    let activeFields = getItem('activeCards', this.props.currentUser.id);
    let tempActiveFields = {};
    if (activeFields) {
      activeFields = JSON.parse(activeFields);
      tempActiveFields = activeFields;
    } else {
      activeFields = this.activeFields;
      tempActiveFields = this.activeFields;
    }

    if (this.props.currentUser.updated_tos || getLoginStack().peek() === 'logged_as_admin') {
      const miniNotesForUsers = (await this.props.fetchSetting('mini_notes_for_users'))?.data?.value || '';
      const notesForUsers = (await this.props.fetchSetting('notes_for_users'))?.data?.value || '';
      await this.props.fetchStatuses();
      await this.props.fetchSources();
      // notesForUsers, statuses & sources must be fetched before set activeFields and loading to init Grid
      await this.setStateAsync({
        activeFields: activeFields,
        tempActiveFields: tempActiveFields,
        miniNotesForUsers,
        notesForUsers,
        loading: false
      });
    }

    if (this.props.currentUser.updated_tos || getLoginStack().peek() === 'logged_as_admin') {
      if (!config().DISABLED_POOLS) {
        await this.props
          .fetchPools()
          .then(async () => {
            const pools =
              this.props.pools?.map(el => {
                return { value: el.id, label: <p className="pool-option">{el.name}</p> };
              }) || [];
            await this.setStateAsync({ groupOptions: [...this.state.groupOptions, ...pools] });
          })
          .catch(res => publish('error', res));
      }

      if (!this.hasAccessOwnerOverride() && (this.props.currentUser.telemarketer || this.props.currentUser.agent)) {
        // same method also used in contacts/index.jsx
        const groupOptions = this.state.groupOptions.filter(el => el.value !== 'downline');
        await this.setStateAsync({ groupOptions });
      }

      await this.fetchDashboardInfo();
    }
  }

  get mainLayout() {
    let _layout = LAYOUT;
    if (this.props.currentUser.c_1) _layout = LAYOUT_C1;
    if (this.props.currentUser.aris) _layout = LAYOUT_ARIS;
    return _layout;
  }

  validateLayout = async () => {
    const currentLayout = this.mainLayout.map(el => el.i);
    const layout = JSON.parse(getItem('dashboardLayout', this.props.currentUser.id))?.map(el => el.i);
    if (!layout || layout.filter(value => !currentLayout.includes(value)).length) {
      await this.onReset();
    }
  };

  changeImpersonated = userObj => {
    this.setState({ selectedImpersonated: userObj.value });
  };

  impersonate = () => {
    this.props
      .loginAsUser(this.state.selectedImpersonated, {
        login_type: LOGIN_TYPES.IMPERSONATE,
        asImpersonated: true
      })
      .catch(res => publish('error', res));
  };

  activeFields() {
    return Object.assign(
      ...this.mainLayout.map(el => ({
        [el.i]: this.defaultActiveField(el)
      }))
    );
  }

  defaultActiveField = el => {
    if (config().ASB_SYSTEM) {
      return ![
        'upcomingTasks',
        'medicareCrossSell',
        'dataCaptureAepOpps',
        'clientAepOpps',
        'clientsWAnnuity',
        'clientsWLife',
        'smsResponders'
      ].includes(el.i);
    }
    if (config().AEGLEADS) {
      let array = ['clientAepOpps', 'dataCaptureAepOpps', 'lifeCrossSell', 'annuityCrossSell', 'medicareCrossSell'];
      if (this.props.currentUser.aris) {
        array.push('policyAnniversary');
      }
      return !array.includes(el.i);
    }
    return true;
  };

  changePasswordCheck = async () => {
    if (this.props.currentAccount['change_password?']) {
      await this.props.fetchCurrentAccount();
      if (this.props.currentAccount['change_password?']) {
        Router.push('/account/profile?tab=password');
        return true;
      }
    }
  };

  accountBillingValidation = () => {
    return (
      this.props.currentAccount &&
      (!this.props.currentAccount.creditcard || !this.props.currentAccount.current_plan) &&
      !this.props.currentAccount.current_plan?.paid_membership &&
      !this.props.currentAccount['paid_user?']
    );
  };

  get twoFactorValidation() {
    return this.props.currentUser['two_fa_is_required?'] && !this.props.currentUser['has_2fa_enabled?'];
  }

  changeContactsGroup = async groupObj => {
    if (groupObj.value === 'mine') {
      await this.setStateAsync({
        contact_group: 'mine',
        all_downline: 'false',
        owners: null
      });
    } else {
      await this.setStateAsync({
        contact_group: groupObj.value,
        all_downline: 'true',
        owners: null
      });
    }
    await this.fetchDashboardInfo();
  };

  greetingsMessage = () => {
    const hour = new Date().getHours();

    let greetingText = 'Good morning';
    if (hour < 12) {
      greetingText = 'Good morning';
    } else if (hour >= 12 && hour < 18) {
      greetingText = 'Good afternoon';
    } else if (hour >= 18 && hour < 20) {
      greetingText = 'Good evening';
    } else if (hour >= 20) {
      greetingText = 'Hello';
    }
    return <h5>{`${greetingText}, ${this.props.currentUser.profile.first_name}`}!</h5>;
  };

  fetchDataParams = async userId => {
    const { contact_group, all_downline } = this.state;
    const poolGroup = this.props.pools?.find(el => el.id === contact_group) || false;
    const group = poolGroup ? 'pools' : contact_group;
    const data = {
      all_downline: contact_group === 'downline' && userId ? 'false' : all_downline,
      contact_group: group === 'mine' ? 'downline' : group,
      pool_id: contact_group,
      downline_user_id: this.props.currentUser.id
    };
    if (userId) data.user_id = userId;
    return data;
  };

  fetchDashboardInfo = async userId => {
    const data = await this.fetchDataParams(userId);
    abortSignal(['v1/dashboard']);
    await this.props.resetDashboardInfo();
    const {
      allLeads,
      clientAepOpps,
      dataCaptureAepOpps,
      lifeCrossSell,
      annuityCrossSell,
      medicareCrossSell,
      clientsWAnnuity,
      clientsWLife,
      partDClients,
      smsResponders,
      soa,
      hotProspects,
      newContacts,
      prospects,
      progress,
      closed,
      clients,
      allContacts,
      upcomingEvents,
      newsLetters,
      clientsBirthdays,
      recentlySavedContacts,
      prospectsBirthdays,
      upcomingTasks,
      policyAnniversary,
      olderPolicies,
      aftercareClients,
      upcomingFollowUps,
      pastDueCalendarEvents,
      summary,
      pending,
      spouseT65
    } = this.state.activeFields;
    const requests = [
      allLeads && DASHBOARD_INFO_TYPES.includes('lead') && 'lead',
      allLeads && DASHBOARD_INFO_TYPES.includes('hot_leads') && 'hot_leads',
      clientAepOpps && DASHBOARD_INFO_TYPES.includes('client_aep_opps') && 'client_aep_opps',
      dataCaptureAepOpps && DASHBOARD_INFO_TYPES.includes('data_capture_aep_opps') && 'data_capture_aep_opps',
      lifeCrossSell && DASHBOARD_INFO_TYPES.includes('life_cross_sell') && 'life_cross_sell',
      annuityCrossSell && DASHBOARD_INFO_TYPES.includes('annuity_cross_sell') && 'annuity_cross_sell',
      medicareCrossSell && DASHBOARD_INFO_TYPES.includes('medicare_cross_sell') && 'medicare_cross_sell',
      clientsWAnnuity && DASHBOARD_INFO_TYPES.includes('clients_w_annuity') && 'clients_w_annuity',
      clientsWLife && DASHBOARD_INFO_TYPES.includes('clients_w_life') && 'clients_w_life',
      partDClients && DASHBOARD_INFO_TYPES.includes('part_d_clients') && 'part_d_clients',
      smsResponders && DASHBOARD_INFO_TYPES.includes('sms_responders') && 'sms_responders',
      soa && DASHBOARD_INFO_TYPES.includes('soa') && 'soa',
      spouseT65 && DASHBOARD_INFO_TYPES.includes('spouse_t65') && 'spouse_t65',
      hotProspects && DASHBOARD_INFO_TYPES.includes('hot_prospects') && 'hot_prospects',
      newContacts && DASHBOARD_INFO_TYPES.includes('new') && 'new',
      prospects && DASHBOARD_INFO_TYPES.includes('prospect') && 'prospect',
      progress && DASHBOARD_INFO_TYPES.includes('progress') && 'progress',
      closed && DASHBOARD_INFO_TYPES.includes('closed') && 'closed',
      clients && DASHBOARD_INFO_TYPES.includes('active_clients') && 'active_clients',
      clients && DASHBOARD_INFO_TYPES.includes('affinity_leads') && 'affinity_leads',
      clients && DASHBOARD_INFO_TYPES.includes('lapsed') && 'lapsed',
      clients && DASHBOARD_INFO_TYPES.includes('client') && 'client',
      pending && DASHBOARD_INFO_TYPES.includes('pending') && 'pending',
      allContacts && DASHBOARD_INFO_TYPES.includes('all') && 'all',
      newsLetters && DASHBOARD_INFO_TYPES.includes('email_logs') && 'email_logs',
      clientsBirthdays && DASHBOARD_INFO_TYPES.includes('client_birthdays') && 'client_birthdays',
      recentlySavedContacts && DASHBOARD_INFO_TYPES.includes('recently_saved') && 'recently_saved',
      prospectsBirthdays && DASHBOARD_INFO_TYPES.includes('prospect_birthdays') && 'prospect_birthdays',
      policyAnniversary && DASHBOARD_INFO_TYPES.includes('policy_aniversary') && 'policy_aniversary',
      olderPolicies && DASHBOARD_INFO_TYPES.includes('older_policies') && 'older_policies',
      aftercareClients && DASHBOARD_INFO_TYPES.includes('aftercare_clients') && 'aftercare_clients',
      upcomingEvents && DASHBOARD_INFO_TYPES.includes('appointment') && 'appointment',
      upcomingTasks && DASHBOARD_INFO_TYPES.includes('task') && 'task',
      upcomingFollowUps && DASHBOARD_INFO_TYPES.includes('followup') && 'followup',
      upcomingFollowUps && DASHBOARD_INFO_TYPES.includes('followup_pastdue') && 'followup_pastdue',
      upcomingFollowUps && DASHBOARD_INFO_TYPES.includes('followup_today') && 'followup_today',
      pastDueCalendarEvents && DASHBOARD_INFO_TYPES.includes('pastdue') && 'pastdue',
      summary && DASHBOARD_INFO_TYPES.includes('summary_prospects') && 'summary_prospects',
      summary && DASHBOARD_INFO_TYPES.includes('summary_leads') && 'summary_leads',
      summary && DASHBOARD_INFO_TYPES.includes('summary_orphans') && 'summary_orphans',
      summary && DASHBOARD_INFO_TYPES.includes('summary_other') && 'summary_other',
      pending && DASHBOARD_INFO_TYPES.includes('pending') && 'pending'
    ].filter(el => el);
    await Promise.all(
      [...new Set(requests)].map(type =>
        this.props.fetchDashboardInfo({ ...data, type }).catch(res => publish('error', res))
      )
    );
  };

  loadMoreBirthdays = async (category, userId) => {
    const name = `${category}BirthdayPage`;
    await this.setStateAsync({ [name]: this.state[name] + 1, [`${name}Loaded`]: false });
    const data = await this.fetchDataParams(userId);
    data.page = this.state[name];
    await this.props.fetchDashboardInfo({ ...data, type: `${category}_birthdays` }).catch(res => publish('error', res));
    await this.setStateAsync({ [`${name}Loaded`]: true });
  };

  onSuccessAction = async () => {
    await this.fetchDashboardInfo().catch(res => publish('error', res));
  };

  selectField = async field => {
    await this.setStateAsync({
      tempActiveFields: {
        ...this.state.tempActiveFields,
        [field]: !this.state.tempActiveFields[field]
      }
    });
  };

  saveFields = async () => {
    await this.setStateAsync({
      loading: true,
      cardsModalVisible: false,
      activeFields: this.state.tempActiveFields
    });
    setItem('activeCards', JSON.stringify(this.state.activeFields));
    this.onSuccessAction();
    await this.setStateAsync({ loading: false });
  };

  loadOwners = async inputValue => {
    const owners = await this.props.searchUsers(inputValue, true, []);
    const data = this.ownersOptions(owners.data);
    return new Promise(resolve => resolve(data));
  };

  ownersOptions = owners => {
    if (owners.length > 0) {
      return owners
        .filter(owner => owner.id !== this.props.currentUser.id)
        .map(owner => ({
          value: owner.id,
          label: `${owner.first_name} ${owner.last_name}`
        }));
    }
  };

  onReset = async () => {
    const activeFields = this.activeFields();
    await this.setStateAsync({
      activeFields,
      tempActiveFields: activeFields,
      resetActive: true,
      cardsModalVisible: false
    });
    await setItem('activeCards', JSON.stringify(activeFields));
    await setItem('dashboardLayout', JSON.stringify(this.mainLayout));
    this.setStateAsync({ resetActive: false });
    this.onSuccessAction();
  };

  closeEditEventModal = async () => {
    this.setState({ editEventModalIsVisible: false });
  };

  openTabEvent = async event => {
    await this.setStateAsync({ editEvent: { eventObject: event.eventObject } });
    this.setState({ editEventModalIsVisible: true });
  };

  hasAccessOwnerOverride = () => MANAGERS_ROLES.includes(this.props.currentUser.owner_override_account_type);

  render() {
    if (this.isRM) return null;
    if (this.accountBillingValidation()) return null;

    return (
      <Wrapper containerFullWidth>
        <div className={styles.dashboard}>
          {config().DASHBOARD_MINI_CALENDAR && (
            <>
              <div className={classNames(styles.heading, this.props.currentUser.c_1 && styles.narrowHeading)}>
                {this.state.loading ? (
                  <LongTitle loading />
                ) : (
                  <div className={styles.miniNotesForUsers}>{this.greetingsMessage()}</div>
                )}
                {this.state.miniNotesForUsers?.length > 0 && this.state.notesForUsers?.length > 0 && (
                  <>
                    <div
                      className={classNames('link', styles.miniNotesForUsers, styles.miniNotesForUsers__center)}
                      onClick={() => document.getElementById('notesForUsers')?.scrollIntoView({ behavior: 'smooth' })}
                    >
                      {this.state.miniNotesForUsers}
                    </div>
                    <div className={styles.miniNotesForUsers}></div>
                  </>
                )}
              </div>
              <MiniCalendar isMini onSuccessAction={this.onSuccessAction} />
            </>
          )}
          <div className={classNames(styles.heading, this.props.currentUser.c_1 && styles.narrowHeading)}>
            {!config().DASHBOARD_MINI_CALENDAR && (
              <>
                {this.state.loading ? (
                  <LongTitle loading />
                ) : (
                  <div className={styles.miniNotesForUsers}>{this.greetingsMessage()}</div>
                )}
                {this.state.miniNotesForUsers?.length > 0 && this.state.notesForUsers?.length > 0 && (
                  <div
                    className={classNames('link', styles.miniNotesForUsers, styles.miniNotesForUsers__center)}
                    onClick={() => document.getElementById('notesForUsers')?.scrollIntoView({ behavior: 'smooth' })}
                  >
                    {this.state.miniNotesForUsers}
                  </div>
                )}
              </>
            )}
            <div className={classNames(styles.subheading)}>
              {config().MULTI_ACCOUNT_ACCESS && !!this.props.currentUser.impersonated_users?.length && (
                <div className={styles.impersonate}>
                  <Select
                    isDisabled={getLoginStack().peek() === 'impersonating'}
                    instanceId={selectImpersonated}
                    classNamePrefix="custom-select"
                    className={classNames('custom-select sorting-select', styles.impersonateSelect)}
                    value={this.impersonatedUser}
                    onChange={this.changeImpersonated}
                    options={this.props.currentUser.impersonated_users.map(user => ({
                      value: user.user_id,
                      label: `${user.first_name} ${user.last_name}`
                    }))}
                  />
                  <button
                    onClick={this.impersonate}
                    disabled={
                      this.state.selectedImpersonated.length === 0 || getLoginStack().peek() === 'impersonating'
                    }
                    className="button is-large is-primary"
                  >
                    Impersonate
                  </button>
                </div>
              )}
              <div className={styles.selector}>
                {this.state.loading ? (
                  <Selector loading />
                ) : (
                  <>
                    {this.state.contact_group === 'downline' && (
                      <AsyncSelect
                        classNamePrefix="custom-select"
                        className={classNames('custom-select', styles.customSelectAgent)}
                        cacheOptions
                        isClearable
                        name="user_id"
                        placeholder="Agent"
                        value={this.state.owners || []}
                        onChange={async value => {
                          await this.setStateAsync({ owners: value });
                          await this.fetchDashboardInfo(value?.value);
                        }}
                        defaultOptions
                        loadOptions={this.loadOwners}
                      />
                    )}
                    <Select
                      style={{ width: `${8 * this.state.contact_group.length + 100}px` }}
                      instanceId={selectedContacts}
                      classNamePrefix="custom-select-dashboard"
                      className={classNames('custom-select sorting-select with-contacts', styles.customSelect)}
                      value={this.state.groupOptions.find(el => el.value === this.state.contact_group)}
                      onChange={this.changeContactsGroup}
                      options={this.state.groupOptions}
                      isSearchable={false}
                    />
                    <div onClick={() => this.setState({ cardsModalVisible: true })} className={styles.settingsButton}>
                      <span className="mbri-setting3" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {this.state.loading ? (
            <CardsWrapper />
          ) : (
            !this.state.resetActive &&
            Object.keys(this.state.activeFields).length > 0 && (
              <DashboardCards
                contact_group={this.state.contact_group}
                all_downline={this.state.all_downline}
                openTabEvent={this.openTabEvent}
                activeFields={this.state.activeFields}
                userId={this.state.owners ? [this.state.owners] : []}
                notesForUsers={this.state.notesForUsers}
                loadMoreBirthdays={this.loadMoreBirthdays}
                clientBirthdayPageLoaded={this.state.clientBirthdayPageLoaded}
                prospectBirthdayPageLoaded={this.state.prospectBirthdayPageLoaded}
              />
            )
          )}
        </div>
        {this.state.cardsModalVisible && (
          <Modal
            header="Choose active sections"
            onClose={() => this.setState({ cardsModalVisible: false })}
            classNames="cards-modal"
          >
            <ActiveCards
              currentUser={this.props.currentUser}
              onReset={this.onReset}
              onSelect={this.selectField}
              onSave={this.saveFields}
              activeFields={this.state.tempActiveFields}
              config={config}
            />
          </Modal>
        )}
        {this.state.editEventModalIsVisible && (
          <Modal classNames={'event-modal'} onClose={this.closeEditEventModal}>
            <EventModal
              downlineId={this.props.currentUser.id}
              onClose={this.closeEditEventModal}
              event={{ ...this.state.editEvent }}
              onSuccessAction={this.onSuccessAction}
            />
          </Modal>
        )}
        {config().CAREINADVANCE && (
          <div className={styles.logo}>
            <img className={styles.logo} src="/static/img/production_careinadvance-logo.png" alt="careinadvance" />
          </div>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  currentAccount: state.currentAccount.info,
  pools: state.admin.users.pools,
  dashboard_info: state.currentUser.dashboard_info,
  sources: state.contacts.contact_sources,
  statuses: state.contacts.contact_statuses,
  accountType: state.currentUser.profile.account_type
});

export default connect(mapStateToProps, {
  searchUsers,
  fetchPools,
  fetchDashboardInfo,
  resetDashboardInfo,
  fetchSources,
  fetchStatuses,
  fetchSetting,
  fetchCurrentAccount,
  loginAsUser,
  clearContacts
})(withErrorBoundary(Dashboard));
