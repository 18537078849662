import React from 'react';
import { withErrorBoundary } from '@/utils/errors';

import SkeletonElement from '@/components/Helpers/SkeletonLoaders/SkeletonElement';
import SkeletonWrapper from '@/components/Helpers/SkeletonLoaders/SkeletonWrapper';

const LongTitle = ({ loading }) =>
  loading ? (
    <SkeletonWrapper noMargin>
      <SkeletonElement type="thumbnail long" />
    </SkeletonWrapper>
  ) : null;

export default withErrorBoundary(LongTitle);
