import React, { useState, useEffect } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';

import styles from './DashboardCalendar.module.scss';

import Calendar from '@/components/Calendar/Calendar';

const DashboardCalendar = ({
  title,
  followup,
  dashboardEvents, // Include dashboardEvents in the props
  followup_pastdue,
  followup_today,
  followup_upcoming,
  loaderWithCondition,
  openTabEvent,
  pastdue
}) => {
  const [events, setEvents] = useState(dashboardEvents);
  const [activeTab, setActiveTab] = useState(followup && 'upcoming');

  const handleTabClick = (tab, events) => {
    setActiveTab(tab);
    setEvents(events);
  };

  useEffect(() => {
    setEvents(dashboardEvents);
  }, [dashboardEvents]);

  return (
    <div className={classNames(styles.contentWrapper, styles.hideScroll)}>
      <div className={classNames(styles.flexBetween, 'DashboardsCardsResponsiveGridLayoutCancelSelector')}>
        <p
          className={classNames(
            'small-title',
            styles.description,
            styles.dark,
            styles.titled,
            'DashboardsCardsResponsiveGridLayoutCancelSelector'
          )}
        >
          {title}
        </p>
        {followup && (
          <div className={classNames(styles.followupBlock)}>
            <p
              className={classNames(activeTab === 'past_due' && styles.active)}
              onClick={() => handleTabClick('past_due', followup_pastdue)}
            >
              Past Due
            </p>
            -
            <p
              className={classNames(activeTab === 'today' && styles.active)}
              onClick={() => handleTabClick('today', followup_today)}
            >
              Today
            </p>
            -
            <p
              className={classNames(activeTab === 'upcoming' && styles.active)}
              onClick={() => handleTabClick('upcoming', followup_upcoming)}
            >
              Upcoming
            </p>
          </div>
        )}
      </div>
      {loaderWithCondition(
        events?.length,
        events?.length ? (
          <Calendar
            key={activeTab}
            listView
            listViewToday={activeTab === 'today'}
            listViewPastDue={activeTab === 'past_due' || pastdue}
            dashboardEvents={events}
            openTabEvent={openTabEvent}
            pastdue={pastdue}
          />
        ) : (
          <p className={classNames('mt-5', styles.middleTitle, styles.noContent)}>No events</p>
        )
      )}
    </div>
  );
};

export default withErrorBoundary(DashboardCalendar);
