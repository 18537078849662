import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import Router from 'next/router';
import classNames from 'classnames';

import { formatNumber } from '@/utils/helpers';

import SVGIcon from '@/components/SVGIcon';

import styles from './DashboardCards.module.scss';

const DashboardFocusSummary = ({ contacts, loaderWithCondition, setStatusSourceSearch }) => {
  const redirectSummary = (category, status) => {
    let statuses = [];
    if (status === 'unworked') {
      statuses = ['Prospect'];
    } else if (status === 'call_back') {
      statuses = ['Call Back', 'Sent Info/Touch 4', 'Data Captured'];
    } else if (status === 'follow_up') {
      statuses = ['Follow Up', 'Fact Finder Done'];
    } else if (status === 'appointment_set') {
      statuses = ['Appointment Set'];
    }
    let sources = [];
    let source_type = 'include';
    if (category === 'summary_prospects') {
      sources = ['General List', 'Info Free', 'T65', 'InfoFree', 'T-65'];
    } else if (category === 'summary_leads') {
      sources = ['Mlead', 'Internet Lead', 'TM Lead', 'Life Lead', 'Mail Lead', 'Jumpstart Lead'];
    } else if (category === 'summary_orphans') {
      sources = ['Orphan', 'Orphan Reworked'];
    } else if (category === 'summary_other') {
      sources = [
        'General List',
        'Info Free',
        'T65',
        'Mlead',
        'Internet Lead',
        'TM Lead',
        'Life Lead',
        'Orphan',
        'Orphan Reworked',
        'Mail Lead',
        'InfoFree',
        'T-65',
        'Jumpstart Lead'
      ];
      source_type = 'exclude';
    }
    setStatusSourceSearch([
      {
        object_type: 'status',
        type: 'include',
        list: statuses
      },
      {
        object_type: 'source',
        type: source_type,
        list: sources
      }
    ]);
    Router.push('/contacts');
  };
  return (
    <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
      <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>focus summary</p>
      <p className={classNames('small-title', styles.description, styles.dark, styles.spaced)}>prospects</p>
      <div className={classNames(styles.cardSection, styles.flexStart)}>
        <div className={classNames(styles.iconWrapper)}>
          <SVGIcon type="allProspects" />
        </div>
        <tbody className={styles.infoTable}>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_prospects?.unworked,
                <span
                  onClick={() => redirectSummary('summary_prospects', 'unworked')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_prospects?.unworked)}
                </span>
              )}
            </td>
            <td>Unworked</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_prospects?.call_back,
                <span
                  onClick={() => redirectSummary('summary_prospects', 'call_back')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_prospects?.call_back)}
                </span>
              )}
            </td>
            <td>Call Back</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_prospects?.follow_up,
                <span
                  onClick={() => redirectSummary('summary_prospects', 'follow_up')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_prospects?.follow_up)}
                </span>
              )}
            </td>
            <td>Follow Up</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_prospects?.appointment_set,
                <span
                  onClick={() => redirectSummary('summary_prospects', 'appointment_set')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_prospects?.appointment_set)}
                </span>
              )}
            </td>
            <td>Appointment Set</td>
          </tr>
        </tbody>
      </div>
      <p className={classNames('small-title', styles.description, styles.dark, styles.spaced)}>leads</p>
      <div className={classNames(styles.cardSection, styles.flexStart)}>
        <div className={styles.iconWrapper}>
          <SVGIcon type="run" />
        </div>
        <tbody className={styles.infoTable}>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_leads?.unworked,
                <span
                  onClick={() => redirectSummary('summary_leads', 'unworked')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_leads?.unworked)}
                </span>
              )}
            </td>
            <td>Unworked</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_leads?.call_back,
                <span
                  onClick={() => redirectSummary('summary_leads', 'call_back')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_leads?.call_back)}
                </span>
              )}
            </td>
            <td>Call Back</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_leads?.follow_up,
                <span
                  onClick={() => redirectSummary('summary_leads', 'follow_up')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_leads?.follow_up)}
                </span>
              )}
            </td>
            <td> Follow Up</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_leads?.appointment_set,
                <span
                  onClick={() => redirectSummary('summary_leads', 'appointment_set')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_leads?.appointment_set)}
                </span>
              )}
            </td>
            <td>Appointment Set</td>
          </tr>
        </tbody>
      </div>
      <p className={classNames('small-title', styles.description, styles.dark, styles.spaced)}>orphans</p>
      <div className={classNames(styles.cardSection, styles.flexStart)}>
        <div className={styles.iconWrapper}>
          <SVGIcon type="allContacts" />
        </div>
        <tbody className={styles.infoTable}>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_orphans?.unworked,
                <span
                  onClick={() => redirectSummary('summary_orphans', 'unworked')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_orphans?.unworked)}
                </span>
              )}
            </td>
            <td>Unworked</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_orphans?.call_back,
                <span
                  onClick={() => redirectSummary('summary_orphans', 'call_back')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_orphans?.call_back)}
                </span>
              )}
            </td>
            <td>Call Back</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_orphans?.follow_up,
                <span
                  onClick={() => redirectSummary('summary_orphans', 'follow_up')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_orphans?.follow_up)}
                </span>
              )}
            </td>
            <td>Follow Up</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_orphans?.appointment_set,
                <span
                  onClick={() => redirectSummary('summary_orphans', 'appointment_set')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_orphans?.appointment_set)}
                </span>
              )}
            </td>
            <td>Appointment Set</td>
          </tr>
        </tbody>
      </div>
      <p className={classNames('small-title', styles.description, styles.dark, styles.spaced)}>other</p>
      <div className={classNames(styles.cardSection, styles.flexStart)}>
        <div className={styles.iconWrapper}>
          <SVGIcon type="allLeads" />
        </div>
        <tbody className={styles.infoTable}>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_other?.unworked,
                <span
                  onClick={() => redirectSummary('summary_other', 'unworked')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_other?.unworked)}
                </span>
              )}
            </td>
            <td>Unworked</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_other?.call_back,
                <span
                  onClick={() => redirectSummary('summary_other', 'call_back')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_other?.call_back)}
                </span>
              )}
            </td>
            <td>Call Back</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_other?.follow_up,
                <span
                  onClick={() => redirectSummary('summary_other', 'follow_up')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_other?.follow_up)}
                </span>
              )}
            </td>
            <td>Follow Up</td>
          </tr>
          <tr>
            <td>
              {loaderWithCondition(
                contacts?.summary_other?.appointment_set,
                <span
                  onClick={() => redirectSummary('summary_other', 'appointment_set')}
                  className={classNames(
                    styles.title,
                    styles.linkTitle,
                    styles.smallDigit,
                    'DashboardsCardsResponsiveGridLayoutCancelSelector'
                  )}
                >
                  {formatNumber(contacts?.summary_other?.appointment_set)}
                </span>
              )}
            </td>
            <td>Appointment Set</td>
          </tr>
        </tbody>
      </div>
    </div>
  );
};

export default withErrorBoundary(DashboardFocusSummary);
