import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';

import styles from './CardsWrapper.module.scss';

import SmallCard from './SmallCard';
import BigCard from './BigCard';
import MediumCard from './MediumCard';

const CardsWrapper = () => (
  <div className={styles.cardsWrapepr}>
    <div className={classNames(styles.column, styles.left)}>
      <SmallCard loading />
      <SmallCard loading />
      <SmallCard loading />
    </div>
    <div className={classNames(styles.column, styles.center)}>
      <div className={styles.section}>
        <SmallCard loading />
        <SmallCard loading />
        <SmallCard loading />
      </div>
      <div className={styles.section}>
        <BigCard loading />
        <BigCard loading />
      </div>
    </div>
    <div className={classNames(styles.column, styles.right)}>
      <MediumCard loading />
      <MediumCard loading />
      <MediumCard loading />
    </div>
  </div>
);

export default withErrorBoundary(CardsWrapper);
