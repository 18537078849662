import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import styles from './DashboardCards.module.scss';

import { formatNumber } from '@/utils/helpers';

import SVGIcon from '@/components/SVGIcon';

/**
 * The MultiInput Functional Component
 * @typedef Props:
 * @property {function} redirectWithFilter - redirect with filter
 * @property {function} loaderWithCondition -
 * @typedef TextFieldProps
 * @property {string} title - title text
 * @property {string} icon - type icon
 * @property {number} contactsNumber - contacts param number
 */

const DashboardCard = ({ title, contactsNumber, loaderWithCondition, redirectWithFilter, icon }) => {
  return (
    <div className={styles.cardSection}>
      <div className={styles.iconWrapper}>{icon && <SVGIcon type={icon} />}</div>
      <div className={styles.sectionInfo}>
        {loaderWithCondition(
          contactsNumber,
          <p
            onClick={() => redirectWithFilter()}
            className={classNames(
              styles.title,
              contactsNumber !== null && styles.linkTitle,
              styles.digit,
              contactsNumber === null && styles.noData,
              'DashboardsCardsResponsiveGridLayoutCancelSelector'
            )}
          >
            {contactsNumber === null ? 'No Data' : formatNumber(contactsNumber)}
          </p>
        )}
        <p className={classNames(styles.smallDescription)}>{contactsNumber === null ? '' : title}</p>
      </div>
    </div>
  );
};

export default withErrorBoundary(DashboardCard);
