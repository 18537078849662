import React from 'react';
import { withErrorBoundary } from '@/utils/errors';

import SkeletonElement from '@/components/Helpers/SkeletonLoaders/SkeletonElement';
import SkeletonWrapper from '@/components/Helpers/SkeletonLoaders/SkeletonWrapper';

import styles from './MediumCard.module.scss';

const bodyLength = 3;

const MediumCard = ({ loading }) =>
  loading ? (
    <div className={styles.mediumCard}>
      <SkeletonWrapper noMargin card>
        <div className={styles.title}>
          <SkeletonElement type="paragraph big margin-bottom" />
        </div>
        {[...Array(bodyLength).keys()].map((el, i) => {
          return (
            <div key={i} className={styles.event}>
              <SkeletonElement type="thumbnail super-small" />
              <SkeletonElement type="paragraph margin-left" />
              <SkeletonElement type="paragraph big margin-left" />
            </div>
          );
        })}
      </SkeletonWrapper>
    </div>
  ) : null;

export default withErrorBoundary(MediumCard);
