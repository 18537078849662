import React from 'react';
import { withErrorBoundary } from '@/utils/errors';

import SkeletonElement from '@/components/Helpers/SkeletonLoaders/SkeletonElement';
import SkeletonWrapper from '@/components/Helpers/SkeletonLoaders/SkeletonWrapper';

import styles from './BigCard.module.scss';

const bodyLength = 6;

const SmallCard = ({ loading }) =>
  loading ? (
    <div className={styles.bigCard}>
      <SkeletonWrapper noMargin card>
        <div className={styles.heading}>
          <SkeletonElement type="paragraph big" />
          <SkeletonElement type="icon margin-left" />
        </div>
        {[...Array(bodyLength).keys()].map((el, i) => {
          return (
            <div key={i} className={styles.list}>
              <div className={styles.initials}>
                <SkeletonElement type="paragraph margin-bottom" />
                <SkeletonElement type="paragraph  margin-bottom" />
              </div>
              <SkeletonElement type="paragraph" />
            </div>
          );
        })}
      </SkeletonWrapper>
    </div>
  ) : null;

export default withErrorBoundary(SmallCard);
