import React, { useState, useEffect, useCallback } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Responsive, WidthProvider } from 'react-grid-layout';
import moment from 'moment';
import Router from 'next/router';

import DashboardCard from './DashboardCard';
import styles from './DashboardCards.module.scss';

import DashboardCalendar from '@/components/Calendar/DashboardCalendar';

import { setItem, getItem, setSessionItem } from '@/utils/storage';
import { config } from '@/config';
import { ADVANCED_SEARCH, DNC_STATUSES } from '@/store/models/contact';
import { LOCAL_STORAGE_SEARCH_KEY } from '@/pages/contacts/advanced-search/index';
import { HtmlParser, isTouchScreen } from '@/utils/helpers';

import Loader from '@/components/Helpers/Loader';
import DashboardBirthdaysCard from './DashboardBirthdaysCard';
import DashboardFocusSummary from './DashboardFocusSummary';

const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardCards = ({
  currentUser,
  dashboard_info,
  statuses,
  sources,

  contact_group,
  all_downline,
  openTabEvent,
  activeFields,
  userId,
  notesForUsers,
  loadMoreBirthdays,
  clientBirthdayPageLoaded,
  prospectBirthdayPageLoaded
}) => {
  const arisStatusFilter = useCallback(form => {
    if (currentUser.aris) {
      const noFieldsForDNC =
        form
          .filter(el =>
            ['policy_anniversary', 'older_policies', 'newsletter_sent', 'newsletter_opened', 'dob_day'].includes(
              el.field
            )
          )
          .map(el => el.value)
          .filter(el => el.length).length > 0;
      if (noFieldsForDNC) {
        let newForm = form.map(el => {
          if (el.field === 'status_id' && el.type === 'exclude') {
            return { ...el, value: '' };
          } else {
            return el;
          }
        });
        return newForm;
      } else {
        const value = statuses.filter(el => DNC_STATUSES.includes(el.label)).map(el => el.value);
        let newForm = form.map(el => {
          if (el.field === 'status_id' && el.type === 'exclude') {
            return { ...el, value };
          } else {
            return el;
          }
        });
        return newForm;
      }
    }
    return form;
  }, []);

  const [advancedSearch, setAdvancedSearch] = useState(() => {
    let form = JSON.parse(JSON.stringify(ADVANCED_SEARCH));
    form = arisStatusFilter(form);
    setSessionItem(LOCAL_STORAGE_SEARCH_KEY, JSON.stringify(form));

    return form;
  });

  const saveAdvancedSearch = useCallback(
    data => {
      let form = data;
      form = arisStatusFilter(form);
      setSessionItem(LOCAL_STORAGE_SEARCH_KEY, JSON.stringify(form));
      setAdvancedSearch(form);
      return form;
    },
    [advancedSearch]
  );
  const [layout, setLayout] = useState(JSON.parse(getItem('dashboardLayout')));

  setSessionItem('all_downline', all_downline || false);
  setSessionItem('contact_group', contact_group || 'mine');

  useEffect(() => {
    const form = advancedSearch.map(el => {
      if (userId && el.field === 'user_id' && el.type === 'include') {
        return { field: el.field, type: el.type, value: userId || '' };
      }
      return el;
    });
    saveAdvancedSearch(form);
  }, [userId]);

  const saveLayout = useCallback(e => {
    setItem('dashboardLayout', JSON.stringify(e));
    setLayout(e);
  }, []);

  const setSearch = useCallback(array => {
    let form = advancedSearch;
    array.map(({ field, type, value }) => {
      return (form = form.map(el => {
        if (el.field === field && el.type === type) {
          return { ...el, value };
        } else {
          return el;
        }
      }));
    });
    saveAdvancedSearch(form);
  }, []);

  const setStatusSourceSearch = useCallback(array => {
    const result = [];
    array.map(element => {
      if (element.object_type === 'status') {
        const value = statuses.filter(el => element.list.includes(el.label)).map(el => el.value);
        result.push({ field: 'status_id', type: element.type, value });
      }
      if (element.object_type === 'source') {
        const value = sources.filter(el => element.list.includes(el.label)).map(el => el.value);
        result.push({ field: 'source_id', type: element.type, value });
      }
      setSearch(result);
    });
  }, []);

  const redirectPending = useCallback(() => {
    setStatusSourceSearch([
      {
        object_type: 'status',
        type: 'include',
        list: ['Submitted/Pending']
      }
    ]);
    Router.push('/contacts');
  }, []);

  const tarkentonCompany = useCallback(() => {
    return config().MYMADCRM && currentUser.company?.name === 'Tarkenton Senior Solutions';
  }, []);

  const redirectWithFilter = useCallback(
    category => {
      setSessionItem('category', category);
      Router.push('/contacts');
    },
    [advancedSearch]
  );

  const redirectToContactDetails = useCallback(id => {
    Router.push('/contacts/[id]', `/contacts/${id}`);
  }, []);

  const redirectBirthdays = useCallback(
    category => {
      setSessionItem('category', category);
      let form = changedObjectForm('dob_day', 'on', moment().format('MM/DD'));
      saveAdvancedSearch(form);
      setStatusSourceSearch([
        {
          object_type: 'status',
          type: 'exclude',
          list: DNC_STATUSES
        }
      ]);
      Router.push('/contacts');
    },
    [advancedSearch]
  );

  const redirectHotLeads = useCallback(() => {
    let changedSourceObject = advancedSearch.find(el => el.field === 'source_id' && el.type === 'include');
    let changedCreatedDateObject = advancedSearch.find(el => el.field === 'created_date');
    let hotLeadSources = config().AEGLEADS
      ? [
          'Seminar',
          'Mail Lead',
          'Internet Lead',
          'Agent Lead',
          'Lead Concepts',
          'ARIS Website',
          'Kramer',
          'TargetLeads'
        ]
      : ['Seminar', 'Mail Lead', 'Internet Lead', 'Agent Lead'];
    if (config().MYASBAGENT || config().MYSIBAGENT || config().ASBMIDWEST || config().LEGACYCRM)
      hotLeadSources.push('TM Lead', 'Affinity Lead');
    if (config().CFGSNE) hotLeadSources.push('TM Lead');
    if (config().HRGAGENT) hotLeadSources.push('TM Lead');
    if (tarkentonCompany()) hotLeadSources.push('Leads2Success');

    changedSourceObject.value = sources
      .filter(el => !config().CAREINADVANCE && hotLeadSources.includes(el.label))
      .map(el => el.value);
    changedCreatedDateObject.value = moment(moment().subtract(30, 'days').calendar()).format('MM/DD/YYYY');
    changedCreatedDateObject.type = 'after';
    let form = advancedSearch.map(el => {
      if (el.field === 'source_id' && el.type === 'include') {
        return changedSourceObject;
      } else if (el.field === 'created_date') {
        return changedCreatedDateObject;
      } else {
        return el;
      }
    });
    saveAdvancedSearch(form);
    setSessionItem('category', 'lead');
    Router.push('/contacts');
  }, [advancedSearch]);

  const redirectHotProspects = useCallback(() => {
    let changedCreatedDateObject = advancedSearch.find(el => el.field === 'created_date');
    changedCreatedDateObject.value = moment(moment().subtract(30, 'days').calendar()).format('MM/DD/YYYY');
    changedCreatedDateObject.type = 'after';
    let form = advancedSearch.map(el => (el.field === 'created_date' ? changedCreatedDateObject : el));
    saveAdvancedSearch(form);
    setSessionItem('category', 'prospect');
    Router.push('/contacts');
  }, [advancedSearch]);

  const redirectLapsedCanceledClients = useCallback(() => {
    setSessionItem('category', currentUser.aris ? '' : 'client');
    let form = changedObjectForm('policy_status', 'include', ['not_active_policyholder']);
    saveAdvancedSearch(form);
    Router.push('/contacts');
  }, [advancedSearch]);

  const redirectCustom = useCallback(
    field => {
      setSessionItem('category', '');
      let form = changedObjectForm(field, 'include', 'true');
      saveAdvancedSearch(form);
      Router.push('/contacts');
    },
    [advancedSearch]
  );

  const changedObjectForm = useCallback(
    (name, type, value) => {
      let changedObject = advancedSearch.find(el => el.field === name && el.type === type);
      changedObject.value = value;
      return advancedSearch.map(el => {
        if (el.field === name && el.type === type) {
          return changedObject;
        } else {
          return el;
        }
      });
    },
    [advancedSearch]
  );

  const redirectClientAEP = useCallback(
    policyTypeValue => {
      let policies_types = [policyTypeValue];
      setSessionItem('category', '');
      let policies_status = [];
      if (config().AEGLEADS) {
        policies_status = ['issued', 'submitted', 'client', 'active', 'pending'];
      } else {
        policies_status = ['issued', 'submitted', 'client'];
      }
      let form = changedObjectForm('policies_status', 'include', policies_status);
      saveAdvancedSearch(form);
      if (config().AEGLEADS) {
        policies_types.push('pdp');
      }
      form = changedObjectForm('policy_type', 'include', policies_types);
      saveAdvancedSearch(form);
      Router.push('/contacts');
    },
    [advancedSearch]
  );

  const redirectCrossSell = useCallback(
    type => {
      setSessionItem('category', '');
      let policies_status = [];
      if (config().AEGLEADS) {
        policies_status = ['issued', 'submitted', 'client', 'active', 'pending'];
      } else {
        policies_status = ['client'];
      }
      let form = changedObjectForm('policies_status', 'include', policies_status);
      saveAdvancedSearch(form);
      let excludeStatuses;
      let includeOptOut;
      switch (type) {
        case 'life':
          excludeStatuses = ['life', 'life_other', 'final_expense', 'single_premium'];
          includeOptOut = `${type}_cross_sell_opt_out`;
          break;
        case 'annuity':
          excludeStatuses = ['annuity'];
          includeOptOut = `${type}_cross_sell_opt_out`;
          break;
        case 'medicare':
          excludeStatuses = ['advantage', 'supplement'];
          includeOptOut = `${type}_cross_sell_opt_out`;
          break;
        default:
          break;
      }
      if (excludeStatuses) {
        form = changedObjectForm('policy_type', 'exclude', excludeStatuses);
        saveAdvancedSearch(form);
        form = changedObjectForm(includeOptOut, 'exclude', true);
        saveAdvancedSearch(form);
      }
      Router.push('/contacts');
    },
    [advancedSearch]
  );

  const redirectClientsW = useCallback(
    type => {
      setSessionItem('category', '');
      let form = changedObjectForm('policies_status', 'include', ['client']);
      saveAdvancedSearch(form);
      let includeStatuses;
      switch (type) {
        case 'life':
          includeStatuses = ['life'];
          break;
        case 'annuity':
          includeStatuses = ['annuity'];
          break;
        default:
          break;
      }
      if (includeStatuses) {
        form = changedObjectForm('policy_type', 'include', includeStatuses);
        saveAdvancedSearch(form);
      }
      Router.push('/contacts');
    },
    [advancedSearch]
  );

  const redirectPartDClients = useCallback(() => {
    setSessionItem('category', '');
    let form = changedObjectForm('policies_status', 'include', ['issued', 'submitted', 'client', 'active', 'pending']);
    saveAdvancedSearch(form);
    form = changedObjectForm('policy_type', 'include', ['medicare_part_d', 'pdp']);
    saveAdvancedSearch(form);
    Router.push('/contacts');
  }, [advancedSearch]);

  const redirectProductInfo = useCallback(
    dataCaptureValue => {
      setSessionItem('category', '');
      let form = changedObjectForm('data_capture_plan_type', 'include', [dataCaptureValue]);
      saveAdvancedSearch(form);
      Router.push('/contacts');
    },
    [advancedSearch]
  );

  const redirectActiveClients = useCallback(() => {
    setSessionItem('category', 'client');
    if (!config().CAREINADVANCE) {
      let form = changedObjectForm('policy_status', 'include', ['active_issued', 'active_submitted']);
      saveAdvancedSearch(form);
    }
    Router.push('/contacts');
  }, [advancedSearch]);

  const redirectAffinityLeads = useCallback(() => {
    setSessionItem('category', 'lead');
    let form = changedObjectForm('policy_status', 'include', ['not_policyholder']);
    saveAdvancedSearch(form);
    let list = ['Affinity Lead'];
    if (config().ASB_SYSTEM && !config().BROKERAGEASB) {
      list.push('Affinity List');
    }
    setStatusSourceSearch([
      {
        object_type: 'source',
        type: 'include',
        list
      }
    ]);
    Router.push('/contacts');
  }, [advancedSearch]);

  const redirectT65 = useCallback(
    type => {
      let changedSpouseDobDateObject = advancedSearch.find(el => el.field === type);
      changedSpouseDobDateObject.value = moment().subtract(64.5, 'years').format('MM/DD/YYYY');
      changedSpouseDobDateObject.type = 'on';
      let form = advancedSearch.map(el => (el.field === type ? changedSpouseDobDateObject : el));
      saveAdvancedSearch(form);
      Router.push('/contacts');
    },
    [advancedSearch]
  );

  const loaderWithCondition = useCallback((condition, component) => {
    return condition !== undefined ? (
      component
    ) : (
      <div className={styles.spinner}>
        <Loader loading miniHeight />
      </div>
    );
  }, []);

  return (
    <div className={styles.dashboardCards}>
      <ResponsiveGridLayout
        measureBeforeMount={false}
        // useCSSTransforms={true}
        draggableCancel=".DashboardsCardsResponsiveGridLayoutCancelSelector"
        onDragStop={e => saveLayout(e)}
        onResizeStop={e => saveLayout(e)}
        className="layout"
        isDraggable={!isTouchScreen()}
        layouts={{ lg: layout, md: layout, sm: layout, xxs: layout }}
        margin={[10, 10]}
        rowHeight={90}
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
      >
        {[
          activeFields.allLeads && (
            <div key="allLeads" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>all leads</p>
                <DashboardCard
                  title="All"
                  icon="allLeads"
                  contactsNumber={dashboard_info.contacts?.lead}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectWithFilter('lead')}
                />
                <DashboardCard
                  title="Hot"
                  icon="run"
                  contactsNumber={dashboard_info.contacts?.hot_leads}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectHotLeads()}
                />
                {config().DASHBOARD_AFFINITY_LEADS && (
                  <DashboardCard
                    title="Affinity Leads"
                    icon="blueDiamond"
                    contactsNumber={dashboard_info.contacts?.affinity_leads}
                    loaderWithCondition={loaderWithCondition}
                    redirectWithFilter={() => redirectAffinityLeads()}
                  />
                )}
              </div>
            </div>
          ),
          activeFields.spouseT65 && (
            <div key="spouseT65" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>T65</p>
                <DashboardCard
                  title="Contact"
                  icon="birthday"
                  contactsNumber={dashboard_info.contacts?.spouse_t65?.contact}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectT65('dob_date')}
                />
                <DashboardCard
                  title="Spouse"
                  contactsNumber={dashboard_info.contacts?.spouse_t65?.spouse}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectT65('spouse_dob_date')}
                />
              </div>
            </div>
          ),
          activeFields.clientAepOpps && (
            <div key="clientAepOpps" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  CLIENT AEP OPPS
                </p>
                <DashboardCard
                  title="MA"
                  icon="clientAepMa"
                  contactsNumber={dashboard_info.contacts?.client_aep_opps?.ma}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectClientAEP('advantage')}
                />
                <DashboardCard
                  title="MS"
                  icon="clientAepMs"
                  contactsNumber={dashboard_info.contacts?.client_aep_opps?.ms}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectClientAEP('supplement')}
                />
              </div>
            </div>
          ),
          activeFields.dataCaptureAepOpps && (
            <div key="dataCaptureAepOpps" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  DATA CAPTURE AEP OPPS
                </p>
                <DashboardCard
                  title="MA"
                  icon="dataCaptureMa"
                  contactsNumber={dashboard_info.contacts?.data_capture_aep_opps?.ma}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectProductInfo('ma')}
                />
                <DashboardCard
                  title="MS"
                  icon="dataCaptureMs"
                  contactsNumber={dashboard_info.contacts?.data_capture_aep_opps?.ms}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectProductInfo('medsupp')}
                />
              </div>
            </div>
          ),
          activeFields.lifeCrossSell && (
            <div key="lifeCrossSell" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  LIFE CROSS SELL
                </p>
                <DashboardCard
                  title="All"
                  icon="lifeCrossSell"
                  contactsNumber={dashboard_info.contacts?.life_cross_sell}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectCrossSell('life')}
                />
              </div>
            </div>
          ),
          activeFields.annuityCrossSell && (
            <div key="annuityCrossSell" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  ANNUITY CROSS SELL
                </p>
                <DashboardCard
                  title="All"
                  icon="annuityCrossSell"
                  contactsNumber={dashboard_info.contacts?.annuity_cross_sell}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectCrossSell('annuity')}
                />
              </div>
            </div>
          ),
          activeFields.medicareCrossSell && (
            <div key="medicareCrossSell" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  MEDICARE CROSS SELL
                </p>
                <DashboardCard
                  title="All"
                  icon="medicareCrossSell"
                  contactsNumber={dashboard_info.contacts?.medicare_cross_sell}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectCrossSell('medicare')}
                />
              </div>
            </div>
          ),
          activeFields.clientsWAnnuity && (
            <div key="clientsWAnnuity" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  CLIENT'S W/ ANNUITY
                </p>
                <DashboardCard
                  title="All"
                  icon="clientsWAnnuity"
                  contactsNumber={dashboard_info.contacts?.clients_w_annuity}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectClientsW('annuity')}
                />
              </div>
            </div>
          ),
          activeFields.clientsWLife && (
            <div key="clientsWLife" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  CLIENT'S W/ LIFE
                </p>
                <DashboardCard
                  title="All"
                  icon="clientsWLife"
                  contactsNumber={dashboard_info.contacts?.clients_w_life}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectClientsW('life')}
                />
              </div>
            </div>
          ),
          activeFields.partDClients && (
            <div key="partDClients" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  PART D CLIENTS
                </p>
                <DashboardCard
                  title="All"
                  icon="activeClients"
                  contactsNumber={dashboard_info.contacts?.part_d_clients}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectPartDClients()}
                />
              </div>
            </div>
          ),
          activeFields.smsResponders && (
            <div key="smsResponders" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  SMS RESPONDERS
                </p>
                <DashboardCard
                  title="All"
                  icon="allLeads"
                  contactsNumber={dashboard_info.sms_responders}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => dashboard_info.sms_responders !== null && Router.push('sms/conversations')}
                />
              </div>
            </div>
          ),
          activeFields.soa && (
            <div key="soa" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>SOA</p>
                <DashboardCard
                  title="Sent"
                  icon="soaSent"
                  contactsNumber={dashboard_info.soa?.sent}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => Router.push('/campaigns/aep/soa?status=sent')}
                />
                <DashboardCard
                  title="Pending by Client"
                  icon="soaPendingClient"
                  contactsNumber={dashboard_info.soa?.pending_client_signature}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => Router.push('/campaigns/aep/soa?status=viewed')}
                />
                <DashboardCard
                  title="Pending by Agent"
                  icon="soaPendingAgent"
                  contactsNumber={dashboard_info.soa?.pending_agent_signature}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => Router.push('/campaigns/aep/soa?status=signed')}
                />
                <DashboardCard
                  title="Completed"
                  icon="soaCompleted"
                  contactsNumber={dashboard_info.soa?.completed}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => Router.push('/campaigns/aep/soa?status=completed')}
                />
              </div>
            </div>
          ),
          activeFields.hotProspects && (
            <div key="hotProspects" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  hot prospects
                </p>
                <DashboardCard
                  title="Hot"
                  icon="run"
                  contactsNumber={dashboard_info.contacts?.hot_prospects}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectHotProspects()}
                />
              </div>
            </div>
          ),
          activeFields.newsLetters && (
            <div key="newsLetters" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>newsletters</p>
                <DashboardCard
                  title="Sent"
                  icon="sent"
                  contactsNumber={dashboard_info.email_logs?.delivered}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectCustom('newsletter_sent')}
                />
                <DashboardCard
                  title="Opened"
                  icon="opened"
                  contactsNumber={dashboard_info.email_logs?.opened}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectCustom('newsletter_opened')}
                />
              </div>
            </div>
          ),
          activeFields.policyAnniversary && (
            <div key="policyAnniversary" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  policy anniversary
                </p>
                <DashboardCard
                  title="All"
                  icon="anniversary"
                  contactsNumber={dashboard_info.policy_aniversary}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectCustom('policy_anniversary')}
                />
              </div>
            </div>
          ),
          activeFields.olderPolicies && (
            <div key="olderPolicies" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  New Contacts for Older Policies
                </p>
                <DashboardCard
                  title="All"
                  icon="allContacts"
                  contactsNumber={dashboard_info.older_policies}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectCustom('older_policies')}
                />
              </div>
            </div>
          ),
          activeFields.aftercareClients && (
            <div key="aftercareClients" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  aftercare clients
                </p>
                <DashboardCard
                  title="All"
                  icon="client"
                  contactsNumber={dashboard_info.contacts?.aftercare_clients}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectWithFilter('deceased')}
                />
              </div>
            </div>
          ),
          activeFields.prospects && (
            <div key="prospects" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>prospects</p>
                <DashboardCard
                  title="All"
                  icon="allProspects"
                  contactsNumber={dashboard_info.contacts?.prospect}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectWithFilter('prospect')}
                />
              </div>
            </div>
          ),
          activeFields.newContacts && (
            <div key="newContacts" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>new</p>
                <div className={styles.spaceBetween}>
                  <DashboardCard
                    title="All"
                    icon="allProspects"
                    contactsNumber={dashboard_info.contacts?.new}
                    loaderWithCondition={loaderWithCondition}
                    redirectWithFilter={() => redirectWithFilter('new')}
                  />
                  <div className={styles.cardSection}>
                    <p
                      className={classNames(
                        styles.title,
                        styles.linkTitle,
                        styles.centered,
                        'DashboardsCardsResponsiveGridLayoutCancelSelector'
                      )}
                      onClick={() => {
                        Router.push('/contacts/new');
                      }}
                    >
                      Create new
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ),
          activeFields.progress && (
            <div key="progress" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>progress</p>
                <DashboardCard
                  title="All"
                  icon="allLeads"
                  contactsNumber={dashboard_info.contacts?.progress}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectWithFilter('progress')}
                />
              </div>
            </div>
          ),
          activeFields.closed && (
            <div key="closed" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>closed</p>
                <DashboardCard
                  title="All"
                  icon="lapsed"
                  contactsNumber={dashboard_info.contacts?.closed}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectWithFilter('closed')}
                />
              </div>
            </div>
          ),
          activeFields.clientsBirthdays && (
            <div key="clientsBirthdays" className={classNames('react-resizable-hide', styles.bigCard)}>
              <DashboardBirthdaysCard
                type="client"
                birthdays={dashboard_info.client_birthdays}
                title="today client's birthdays"
                loaderWithCondition={loaderWithCondition}
                redirectToContactDetails={redirectToContactDetails}
                redirectBirthdays={() => redirectBirthdays('client')}
                loadMore={() => loadMoreBirthdays('client')}
                clientBirthdayPageLoaded={clientBirthdayPageLoaded}
              />
            </div>
          ),
          activeFields.prospectsBirthdays && (
            <div key="prospectsBirthdays" className={classNames('react-resizable-hide', styles.bigCard)}>
              <DashboardBirthdaysCard
                type="prospect"
                birthdays={dashboard_info.prospect_birthdays}
                title="today prospect's birthdays"
                loaderWithCondition={loaderWithCondition}
                redirectToContactDetails={redirectToContactDetails}
                redirectBirthdays={() => redirectBirthdays('prospect')}
                loadMore={() => loadMoreBirthdays('prospect')}
                prospectBirthdayPageLoaded={prospectBirthdayPageLoaded}
              />
            </div>
          ),
          activeFields.clients && (
            <div key="clients" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.dark, styles.description, styles.titled)}>clients</p>
                {currentUser.c_1 ? (
                  <DashboardCard
                    title="All"
                    icon="activeClients"
                    contactsNumber={dashboard_info.contacts?.client}
                    loaderWithCondition={loaderWithCondition}
                    redirectWithFilter={() => redirectWithFilter('client')}
                  />
                ) : (
                  <>
                    <DashboardCard
                      title={config().BROKERAGEASB ? 'All' : 'Active'}
                      icon="activeClients"
                      contactsNumber={
                        config().BROKERAGEASB
                          ? dashboard_info.contacts?.client
                          : dashboard_info.contacts?.active_clients
                      }
                      loaderWithCondition={loaderWithCondition}
                      redirectWithFilter={
                        config().BROKERAGEASB ? () => redirectWithFilter('client') : redirectActiveClients
                      }
                    />
                    {!config().BROKERAGEASB && !config().CAREINADVANCE && (
                      <DashboardCard
                        title="Lapsed/ Canceled"
                        icon="lapsed"
                        contactsNumber={dashboard_info.contacts?.lapsed}
                        loaderWithCondition={loaderWithCondition}
                        redirectWithFilter={() => redirectLapsedCanceledClients()}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ),
          activeFields.allContacts && (
            <div key="allContacts" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>
                  all contacts
                </p>
                <DashboardCard
                  title="All"
                  icon="allContacts"
                  contactsNumber={dashboard_info.contacts?.all}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectWithFilter('')}
                />
              </div>
            </div>
          ),
          activeFields.upcomingEvents && (
            <div key="upcomingEvents" className={classNames(styles.mediumCard)}>
              <DashboardCalendar
                loaderWithCondition={loaderWithCondition}
                openTabEvent={openTabEvent}
                dashboardEvents={dashboard_info.appointment}
                title={currentUser.c_1 ? 'upcoming calendar events' : 'upcoming appointments'}
              />
            </div>
          ),
          activeFields.pastDueCalendarEvents && (
            <div key="pastDueCalendarEvents" className={classNames(styles.mediumCard)}>
              <DashboardCalendar
                loaderWithCondition={loaderWithCondition}
                openTabEvent={openTabEvent}
                dashboardEvents={dashboard_info.pastdue}
                pastdue
                title={'past due calendar events'}
              />
            </div>
          ),
          activeFields.upcomingTasks && (
            <div key="upcomingTasks" className={classNames(styles.mediumCard)}>
              <DashboardCalendar
                loaderWithCondition={loaderWithCondition}
                openTabEvent={openTabEvent}
                dashboardEvents={dashboard_info.task}
                title={'upcoming tasks'}
              />
            </div>
          ),
          activeFields.upcomingFollowUps && (
            <div key="upcomingFollowUps" className={classNames(styles.mediumCard)}>
              <DashboardCalendar
                loaderWithCondition={loaderWithCondition}
                openTabEvent={openTabEvent}
                dashboardEvents={dashboard_info.followup}
                followup_upcoming={dashboard_info.followup}
                followup_pastdue={dashboard_info.followup_pastdue}
                followup_today={dashboard_info.followup_today}
                followup={config().CAREINADVANCE}
                title={`${config().CAREINADVANCE ? '' : 'upcoming '}follow ups`}
              />
            </div>
          ),
          activeFields.recentlySavedContacts && (
            <div key="recentlySavedContacts" className={classNames(styles.bigCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <div className={styles.bigCardTitle}>
                  <div className={classNames('small-title', styles.dark, styles.description, styles.titled)}>
                    <p>recently saved</p>
                  </div>
                </div>
                <div className={styles.sectionContent}>
                  {dashboard_info.recently_saved?.length > 0 || dashboard_info.recently_saved === undefined ? (
                    loaderWithCondition(
                      dashboard_info.recently_saved,
                      dashboard_info.recently_saved?.map(contact => (
                        <div key={contact.id} className={classNames(styles.bigCardSection, styles.recentlySavedDiv)}>
                          <div className={styles.info}>
                            <p
                              onClick={() => redirectToContactDetails(contact.number)}
                              className={classNames(
                                styles.title,
                                styles.linkTitle,
                                styles.smallDescription,
                                'DashboardsCardsResponsiveGridLayoutCancelSelector'
                              )}
                            >
                              {contact.first_name} {contact.last_name}
                            </p>
                          </div>
                        </div>
                      ))
                    )
                  ) : (
                    <div className={styles.bigCardSection}>
                      <p className={classNames(styles.middleTitle, styles.noContent)}>No recently saved</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ),
          activeFields.summary && (
            <div key="summary" className={classNames(styles.smallCard)}>
              <DashboardFocusSummary
                contacts={dashboard_info.contacts}
                loaderWithCondition={loaderWithCondition}
                setStatusSourceSearch={setStatusSourceSearch}
              />
            </div>
          ),
          activeFields.pending && (
            <div key="pending" className={classNames(styles.smallCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <p className={classNames('small-title', styles.description, styles.dark, styles.titled)}>pending</p>
                <DashboardCard
                  title="All"
                  icon="client"
                  contactsNumber={dashboard_info.contacts?.pending}
                  loaderWithCondition={loaderWithCondition}
                  redirectWithFilter={() => redirectPending()}
                />
              </div>
            </div>
          ),
          notesForUsers && (
            <div key="notesForUsers" id="notesForUsers" className={classNames(styles.mediumCard)}>
              <div className={classNames(styles.cardContentWrapper, styles.scrollHidden)}>
                <div className={config().CAREINADVANCE ? styles.careInAdvance : undefined}>
                  <div style={{ margin: '10px' }} className="DashboardsCardsResponsiveGridLayoutCancelSelector">
                    {HtmlParser(notesForUsers)}
                  </div>
                </div>
              </div>
            </div>
          )
        ]
          .filter(el => el)
          .map(el => el)}
      </ResponsiveGridLayout>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  dashboard_info: state.currentUser.dashboard_info,
  statuses: state.contacts.contact_statuses,
  sources: state.contacts.contact_sources
});

export default connect(mapStateToProps, {})(withErrorBoundary(DashboardCards));
